<script>
import { mapGetters } from "vuex";
import * as moment from "moment";
import toDouble from "@/helpers/toDouble";

export default {
  components: {
    GModal: () => import("@/components/g-modal.vue"),
    TableV: () => import("@/components/table-v.vue"),
  },
  props: {
    funcionarioId: {
      type: Number,
    },
  },
  data() {
    return {
      cols: [],
      disabledAdjustment: false,
      errorMessage: "",
      funcionarioName: "",
      modalOpened: false,
      rows: [],
    };
  },
  computed: {
    ...mapGetters(["clientId", "intervaloCompetencia", "servico"]),
    resource() {
      return this.apiResource(
        `v1/timesheet/${this.clientId}/ajusteAutomatico`
      );
    },
  },
  watch: {},
  methods: {
    doLoad(ajustar = false) {
      let query = `trabalho=${this.servico}&competenciaIni=${this.intervaloCompetencia[0]}&competenciaFim=${this.intervaloCompetencia[1]}&funcionarioId=${this.funcionarioId}`;

      if (ajustar) {
        query += '&ajustar=true';
      }

      return this.resource.get({ query });
    },
    async openHandler() {
      try {
        this.disabledAdjustment = true;

        if (Number.isNaN(this.funcionarioId)) {
          throw new Error("funcionarioId inválido.");
        }

        const response = await this.doLoad();
        const { funcionario, ...competencias } = response;
        this.funcionarioName = `${funcionario.matricula ?? "SEM MATRICULA"} — ${
          funcionario.nome
        }`;

        // Cria as colunas da tabela
        this.cols = Object.keys(competencias).map((comp) => ({
          key: comp,
          name: moment(comp).format("MM/yyyy"),
          type: this.$fieldTypes.HTML,
          align: 0,
        }));
        this.cols.unshift({
          key: "label",
          name: "",
        });

        // Labels das linha que não são projetos
        const calcLabels = {
          horas_trabalhadas: "Horas trabalhadas",
          timesheets_elegiveis: "Timesheets elegiveis",
          diferenca_elegivel: "Diferença elegivel",
        };

        // Transforma os dados em linhas da tabela
        let pseudoRows = {};
        Object.entries(competencias).forEach(
          ([comp, { calc, mais, menos }]) => {
            Object.entries(calc).forEach(([key, value]) => {
              if (key === "competencia") {
                return;
              }

              if (!(key in pseudoRows)) {
                pseudoRows[key] = {
                  label: calcLabels[key],
                };
              }

              pseudoRows[key][comp] = this.formatHours(value);
            });

            [mais, menos].forEach((array) => {
              if (!Array.isArray(array)) {
                return;
              }

              array.forEach(({ projetoId, titulo, ajuste }) => {
                if (!(projetoId in pseudoRows)) {
                  pseudoRows[projetoId] = {
                    label: titulo,
                  };
                }

                pseudoRows[projetoId][comp] = this.formatHours(ajuste);
              });
            });
          }
        );

        // Preenche células da tabela que não possuem dados com 0
        Object.keys(competencias).forEach((comp) => {
          Object.keys(pseudoRows).forEach((key) => {
            if (!pseudoRows[key][comp]) {
              pseudoRows[key][comp] = this.formatHours(0);
            }
          });
        });

        // Ordena os projetos para aparecer por ultimo e transforma no formato aceito pelo TableV
        this.rows = Object.entries(pseudoRows)
          .sort(([keyA], [keyB]) => {
            const [AisNumber, BisNumber] = [!isNaN(keyA), !isNaN(keyB)];
            return AisNumber && !BisNumber
              ? 1
              : !AisNumber && BisNumber
              ? -1
              : 0;
          })
          .map(([, value]) => value);

        this.disabledAdjustment = false;
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
        console.error(this.errorHandler(error));
      }
      this.modalOpened = true;
    },
    doAdjustment: async function () {
      try {
        await this.doLoad(true);
        this.modalOpened = false;
        this.$emit("reload");
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
        console.error(this.errorHandler(error));
      }
    },
    formatHours(cellValue) {
      if (cellValue < 0) {
        return `
          <span class="text-red">${toDouble(cellValue)}</span>
        `;
      }

      if (cellValue > 0) {
        return `
          <span class="text-blue">${toDouble(cellValue)}</span>
        `;
      }

      return `
        <span class="text-gray">0</span>
      `;
    },
  },
};
</script>

<template>
  <GModal
    v-model="modalOpened"
    appendTitleIcon="mdi-clock-alert-outline"
    noPadding
    title="Ajustes automatico de horas"
  >
    <template v-if="rows && rows.length">
      <p class="ml-4">Preview das alterações de {{ funcionarioName }}.</p>
      <TableV
        :cols="cols"
        disablePagination
        :hasFilter="false"
        height="min-content"
        :rows="rows"
      />
    </template>
    <template v-else>
      <p class="ml-4">Não é necessário ajustar as horas de {{ funcionarioName }}.</p>
    </template>

    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <template v-slot:buttons="{ close }">
      <v-spacer />
      <v-btn
        class="px-3"
        color="secondary"
        dark
        depressed
        @click="close(false)"
      >
        Cancelar
      </v-btn>
      <v-btn
        class="px-3"
        color="primary"
        dark
        depressed
        :disabled="disabledAdjustment"
        @click="doAdjustment"
      >
        Ajustar
      </v-btn>
    </template>
  </GModal>
</template>

<style lang="scss" scoped>
::v-deep {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 12px !important;
    height: 32px;
  }
  
  .v-data-table > .v-data-table__wrapper > table > tbody > tr:nth-child(3) > td {
    border-width: 4px !important;
  }

  .text-blue {
    color: #3094ff;
  }

  .text-gray {
    color: rgba(0, 0, 0, 0.3);
  }

  .text-red {
    color: #ec2020;
  }
}
</style>
